import { Link } from 'gatsby';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { RegularHero } from '../components/RegularHero';
import { StaticImage } from 'gatsby-plugin-image';
import { Doctor } from '../components/Doctor';
import { SlantedPicture } from '../components/SlantedPicture';
import { PicturePanels } from '../components/PicturePanels';
import { Button } from '../components/layout/StyledComponents';
import { colors, device } from '../components/layout/GlobalStyles';
import styled from '@emotion/styled';
import { Seo } from '../components/Seo';

const StyledText = styled.div`
    padding: 1em;

    p {
        margin: 1em 0em;
    }
    @media ${device.tablet} and (max-width: 1028px) {
        h2 {
            font-size: 42px;
        }
    }
    @media ${device.tablet} {
        max-width: 1400px;
        margin: 0 auto;
    }
`;

const OurDifference: Page = () => (
    <>
        <DefaultLayout>
            <Seo
                title="About Us Lutz, FL | Smilecraft Dental Studio"
                description="At SmileCraft, we value your time and your comfort, and we strive to give every patient who walks through our door a superior dental experience."
            />
            <RegularHero prevPage="home" currentPage="Our Difference">
                <StaticImage
                    quality={100}
                    className="regular-hero-image"
                    src="../images/plants.jpg"
                    placeholder="blurred"
                    alt="green plants"
                />
            </RegularHero>
            <StyledText>
                <h2>Fostering A Caring Community</h2>
                <h3>Our Unique Approach to Patient Care</h3>
                <p>
                    At SmileCraft, dentistry is our passion. We take pride in our work and make
                    excellence our priority. We are about you. We care about you as a person. We are
                    here to help you learn and understand your treatment solutions, and help you
                    make decisions that are best for you. With industry-leading technology and
                    professional care, we have created a fun, friendly, judgment-free space to offer
                    a dental experience like no other. We aim to ensure you don't dread the dentist.
                    We are pretty sure you will look forward to every visit!
                </p>
            </StyledText>
            <Doctor />
            <PicturePanels />
        </DefaultLayout>
    </>
);

export default OurDifference;
