import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';
import { SlantedPicture } from './SlantedPicture';

const StyledPicturePanels = styled.div`
    background: ${colors.white};

    margin: 0 auto;

    padding: 3em 0em;
    p {
        padding: 0em 1em;
        margin-bottom: 2em;
    }
    .title {
        padding: 0em 1em;
        font-size: 32px;
        line-height: 44.8px;
        text-align: center;
    }

    .text-container {
        h2 {
            margin-top: 1em;
        }
        p {
            padding: 0em;
            margin-top: 1em;
        }
        button {
            margin-bottom: 2em;
        }
    }
    .sub {
        margin-bottom: 1em;
        padding: 0.2em 1em;
        font-size: 48px;
        font-weight: 900;

        color: ${colors.navy};
        line-height: 44.8px;
        text-align: center;
    }
    @media ${device.tablet} {
        max-width: 700px;
        .flex-container {
            margin: 2em 0em;
        }
        .text-container {
            h2 {
                margin-top: -1em;
            }
        }
        img {
            margin-top: 2em;
            width: 500px;
            height: 400px;
        }
        .spec {
            padding-top: 6em;
        }
    }
    @media ${device.laptop} {
        max-width: 1200px;
        .flex-container {
            margin: 2em 0em;
        }
        .text-container {
            h2 {
                margin-top: 0em;
            }
        }
        .spec {
            padding-top: 6em;
        }
    }
    @media ${device.laptopL} {
        max-width: 1400px;
        .crisis {
            position: relative;
            left: 13.5em;
        }
        .flex-container {
            padding: 0em 2em;

            margin: 2em 0em;
        }

        .text-container {
            p {
                width: 590px;
            }
            h2 {
                font-size: 48px;
                margin-top: 0em;
            }
        }
        .spec {
            padding-top: 6em;
        }
    }
    @media ${device.desktop} {
        .right-paragraph {
            position: relative;
            right: 100px;
        }
    }
`;

export const PicturePanels = () => {
    return (
        <StyledPicturePanels>
            <h2 className="title">Going Beyond Dentistry</h2>
            <p className="sub">Our Give</p>
            <p style={{ textAlign: 'center' }}>
                At SmileCraft Dental Studio, your visit can help us make an impact in our community
            </p>

            <SlantedPicture reverse>
                <div className="flex-container">
                    <div className="image-container">
                        <StaticImage
                            quality={100}
                            src="../images/sunlight-group.jpg"
                            placeholder="none"
                            alt="group meeting in the sun"
                        />
                    </div>
                    <div className="text-container">
                        <h2 className="right-paragraph">Spreading Awareness For Mental Health</h2>
                        <p className="right-paragraph">
                            At SmileCraft, we believe mental health does not discriminate. The lack
                            of mental health resources, the lack of awareness, and the stigma
                            surrounding mental illness and suicide prevention needs to be
                            alleviated. That’s why we’re on a mission to spread awareness for those
                            who need it most. Mental health is everyone’s responsibility, and we’re
                            doing our part to spread awareness both near and far.
                        </p>
                    </div>
                </div>
            </SlantedPicture>
            <SlantedPicture>
                <div className="flex-container">
                    <div className="image-container">
                        <StaticImage
                            quality={100}
                            className="crisis"
                            src="../images/crisis-center-logo.jpg"
                            placeholder="none"
                            alt="Crisis Center logo"
                        />
                    </div>
                    <div className=" text-container">
                        <h2>Supporting The Crisis Center Of Tampa Bay</h2>
                        <p>
                            As a way to bring our community together, Dr. Kasey and the SmileCraft
                            team have partnered with the Crisis Center of Tampa Bay’s 211 Support
                            Line, a local non-profit that offers 24 hour immediate phone support and
                            over 3,000 available resources for anyone struggling or just feels like
                            they need to be heard. In an effort to raise awareness and keep our
                            community healthy, Dr. Kasey will support one hour of talk time every
                            time a new patient comes to at SmileCraft.
                        </p>
                        <a
                            href="https://www.crisiscenter.com/"
                            rel="noopener noreferrer"
                            target="__blank"
                        >
                            <Button
                                style={{ color: `${colors.white}`, background: `${colors.gold}` }}
                                color="gold"
                            >
                                Get Involved
                            </Button>
                        </a>
                    </div>
                </div>
            </SlantedPicture>
            <SlantedPicture reverse>
                <div className="flex-container">
                    <div className="image-container">
                        <StaticImage
                            quality={100}
                            src="../images/group-meet.jpg"
                            placeholder="none"
                            alt="group meeting"
                        />
                    </div>
                    <div className="text-container">
                        <h2 className="right-paragraph">Why Outreach Matters</h2>
                        <p className="right-paragraph">
                            We understand that people are people. No matter where you’re from, or
                            what your socioeconomic background is, we all deserve respect, comfort
                            and good health. We know that by starting this uncomfortable
                            conversation, we can come together and bring awareness to this growing
                            issue.
                        </p>
                        <p className="right-paragraph">
                            We aim to spread awareness to those who otherwise would not receive it,
                            and by doing so in a positive and comfortable way, we can transform
                            people’s lives and the way they view mental health. We aim to let anyone
                            struggling know that there is help available and it’s okay to ask for
                            it.
                        </p>
                    </div>
                </div>
            </SlantedPicture>
        </StyledPicturePanels>
    );
};
