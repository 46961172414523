import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';
import rectangle from '../images/svgs/rectangle.svg';
import girl from '../images/homepage.jpg';
import { css, cx } from '@emotion/css';

const StyledHomeDoctor = styled.div`
    padding: 5em 0em 1em;
    .doctor-container {
        position: relative;
        display: flex;
        justify-content: center;
    }
    .shadow {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .navy-square {
        width: 100vw;
        position: absolute;
        z-index: 0;
    }
    .text-container {
        padding: 0em 1em;
    }
    .doctor {
        width: 100vw;
        z-index: 1;
        margin-bottom: 2em;
    }
    position: relative;
    .side-lines {
        display: none;
    }
    h3 {
        margin: 1em 0em 0.75em;
    }
    p {
        margin-top: 1em;
    }
    button {
        margin: 2em 0em;
    }
    @media ${device.tablet} {
        .side-lines {
            display: none;
            z-index: -1;
            top: 20em;
            position: absolute;
        }
        padding: 8em 0em;
        .doctor-container {
            min-width: 300px;
        }
        .doctor {
            border-radius: 20px;
            margin-bottom: -8em;
            width: 500px;

            z-index: 1;
        }
        .navy-square {
            width: 50%;
            height: 500px;
            position: absolute;
            z-index: 0;
        }
        .text-container {
            margin-top: 7em;
            padding: 4em;
        }
    }
    @media ${device.laptop} {
        display: flex;
        .doctor-container,
        .text-container {
            margin-top: 0em;
            width: 50%;
        }
        .doctor {
            width: 500px;
            height: 700px;
            z-index: 1;
        }
        .navy-square {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 0;
        }
        .text-container {
            padding: 0em 4.25em 0em 4em;
        }
    }
    @media ${device.laptopL} {
        margin: 0em 0em -4em;
        .doctor-container {
        }
        .text-container {
            width: 45%;
            padding: 0em 4.25em 0em 0em;
            margin-left: -2em;
        }

        .navy-square {
            width: auto;
            right: 7.25em;
            height: 804px;
            position: absolute;
            z-index: 0;
            bottom: -4em;
        }
        .doctor {
            width: 600px;
            height: 900px;
        }
    }
    @media ${device.desktop} {
        max-width: 1560px;
        margin: 0em auto 4.5em;
        .side-lines {
            bottom: -41em;
            min-width: 100vw;
        }
    }
`;

export const Doctor: React.FC = () => {
    return (
        <StyledHomeDoctor>
            <div className="doctor-container">
                <StaticImage
                    quality={100}
                    className="shadow doctor"
                    src="../images/doctor.jpg"
                    placeholder="none"
                    alt="doctor silhoette"
                />
            </div>
            <div className="text-container">
                <h2>Meet Dr. Kasey</h2>
                <p>
                    Dr. Kasey Cowser grew up in Steubenville, Ohio, a small south eastern steel town
                    nestled along the Ohio River. She graduated from Notre Dame College of Ohio with
                    a B.S. in biology and minored in chemistry. At a young age she had dreams and
                    aspirations to work in a profession where she could and would change people’s
                    lives.
                </p>
                <p>
                    After graduating in the top 10 percent of her class, she completed a one-year
                    general practice residency at Toledo University Medical Center in Ohio. As one
                    of only four residents chosen for this competitive program, she received
                    extensive training in all aspects of general dentistry, including special needs.{' '}
                </p>
                <p>
                    After completing her residency program, Dr. Kasey accepted a position in our
                    beautiful sunshine state of Florida, where she quickly emerged as a top dentist
                    throughout The Villages Community Development Districts. She offers a full range
                    of preventive and restorative dental services, and she holds advanced
                    certifications in injectable Botox and fillers. She focuses her practice on
                    cosmetic and implant surgeries with attention to improving the esthetics of each
                    smile.
                </p>
                <p>
                    Outside of SmileCraft, she enjoys spending time with loved-ones, bike-riding
                    local trails, and traveling. One of her favorite hobbies consists of discovering
                    local restaurants and eateries and brewing her own beers.
                </p>
                <Link to="/our-difference/">
                    <Button color="blue">Learn More About Dr Kasey</Button>
                </Link>
            </div>
            <StaticImage
                className="side-lines"
                src="../images/side-lines.png"
                placeholder="none"
                alt="lines on the sides"
            />
        </StyledHomeDoctor>
    );
};
